<template>
  <div class="page">
    <head-nav />
    <div
      class="inner-ban"
      :style="{ backgroundImage: 'url(' + banner + ')' }"
    ></div>
    <div class="main">
      <div class="auto wrap flexLr">
        <lefter :bg="menuBackground" @click="achievementFun" />
        <!-- <div class="main-l">
          <div
            class="menu-titbox"
            :style="{ backgroundImage: 'url(' + menuBackground + ')' }"
          >
            学术成果
          </div>
          <ul class="menu-list">
            <li
              :class="achievementIndex === item.typeCodeNum ? 'curBg' : ''"
              v-for="(item, index) in achievementLists"
              :key="index"
              @click="achievementFun(item)"
            >
              {{ item.names }}
            </li>
          </ul>
        </div> -->
        <div class="main-r flex-1 bg-white">
          <div class="conbx" v-show="!dialogTableVisible">
            <div class="inner-tt">
              <span class="bt">{{ achievementTitle }}</span>
            </div>
            <div class="main-wrap cx-wrap">
              <ul class="kjcg-list">
                <li
                  v-for="(item, index) in achievementDetailsList"
                  :key="index"
                  class="item flex flex-pack-justify"
                  @click="achievementDetailsFun(item)"
                >
                  <h6 class="title ellipsis" :title="item.ItemName">
                    {{ item.ItemName }}
                  </h6>
                  <span class="date">
                    {{
                      item.SF49
                        ? common.dateFormat("YYYY-mm-dd", item.SF49.toString())
                        : ""
                    }}
                  </span>
                </li>
              </ul>
              <div class="pagelist" v-show="dataCount > 10">
                <el-pagination
                  @size-change="handleSizeChange"
                  @current-change="handleCurrentChange"
                  :current-page="currentPage"
                  :page-sizes="[10, 15, 20, 25]"
                  :page-size="pageSize"
                  :total="dataCount"
                  layout="prev, pager, next"
                >
                </el-pagination>
              </div>
            </div>
          </div>
          <div class="popbx" v-if="dialogTableVisible">
            <el-dialog
              :title="detailsTitle"
              :visible.sync="dialogTableVisible"
              :modal="false"
              class="new-dialog"
            >
              <detail ref="sonV" type="1" />
            </el-dialog>
          </div>
        </div>
      </div>
    </div>

    <foot />
  </div>
</template>

<script>
import headNav from "@/components/head.vue";
import foot from "@/components/foot.vue";
import detail from "@/components/detail.vue";
import Lefter from "@/components/lefter.vue";
export default {
  name: "",
  data() {
    return {
      banner: require("@/assets/images/kjcg_01.jpg"),
      lefterData: {},
      menuBackground: require("@/assets/images/navBg02.jpg"),
      tolerantImg: require("@/assets/images/jiangbei.png"),

      achievementIndex: "CX005",
      achievementLists: [
        {
          names: "专利",
          rootIdNum: "10000",
          itemIdNum: "16",
          typeCodeNum: "CX005",
        },
        {
          names: "专著",
          rootIdNum: "10000",
          itemIdNum: "17",
          typeCodeNum: "CX006",
        },
        {
          names: "论文",
          rootIdNum: "10000",
          itemIdNum: "18",
          typeCodeNum: "CX007",
        },
        {
          names: "标准",
          rootIdNum: "10000",
          itemIdNum: "19",
          typeCodeNum: "CX008",
        },
        {
          names: "软著",
          rootIdNum: "10000",
          itemIdNum: "20",
          typeCodeNum: "CX009",
        },
        {
          names: "科研报告",
          rootIdNum: "10000",
          itemIdNum: "21",
          typeCodeNum: "CX010",
        },
        // {
        //   names: "其它成果",
        //   rootIdNum: "10000",
        //   itemIdNum: "108",
        //   typeCodeNum: "CX026",
        // },
      ],
      rootIdNum: "",
      itemIdNum: "",
      typeCodeNum: "",
      // 标题
      achievementTitle: "",
      achievementDetailsList: [],
      dialogTableVisible: false,
      detailsTitle: "",
      releaseTime: "",
      // detailsCentent: "",
      //默认第一页
      currentPage: 1,
      //数据总条数
      dataCount: 0,
      pageSize: 10,
    };
  },
  components: {
    headNav,
    foot,
    detail,
    Lefter,
  },
  watch: {
    $route(to, from) {
      if (this.$route.query.typeCode) {
        var typeCode = this.$route.query.typeCode;
        for (var index in this.achievementLists) {
          var item = this.achievementLists[index];
          if (item.typeCodeNum == typeCode) {
            this.achievementFun(item);
          }
          if (item.typeCodeNum == typeCode && this.$route.query.supItemId) {
            this.UrlDetailRedirect();
          }
        }
      } else {
        var item = this.achievementLists[0];
        this.achievementFun(item);
      }
    },
  },
  mounted() {
    let that = this;
    that.rootIdNum = that.achievementLists[0].rootIdNum;
    that.itemIdNum = that.achievementLists[0].itemIdNum;
    that.typeCodeNum = that.achievementLists[0].typeCodeNum;
    // 初始化加载奖励列表
    that.send(
      that.rootIdNum,
      that.itemIdNum,
      that.typeCodeNum,
      that.currentPage
    );
    that.achievementTitle = that.achievementLists[0].names;
    that.UrlDetailRedirect();
  },
  methods: {
    achievementFun(item) {
      console.log(2222, item);
      let that = this;
      that.achievementIndex = item.typeCodeNum;
      that.achievementTitle = item.names;
      that.dialogTableVisible = false;
      that.rootIdNum = item.rootIdNum;
      that.itemIdNum = item.itemIdNum;
      that.typeCodeNum = item.typeCodeNum;
      that.send(that.rootIdNum, that.itemIdNum, that.typeCodeNum);
      document.documentElement.scrollTop = document.body.scrollTop = 0;
    },
    // 加载奖励列表
    send(rootIdNum, itemIdNum, typeCodeNum, currentPage) {
      let that = this;
      if (rootIdNum == "" && itemIdNum == "" && typeCodeNum == "") {
        rootIdNum = that.rootIdNum;
        itemIdNum = that.itemIdNum;
        typeCodeNum = that.typeCodeNum;
      }
      var obj = {
        rootId: rootIdNum,
        itemId: itemIdNum,
        typeCode: typeCodeNum,
        pageIndex: currentPage,
        pageSize: that.pageSize,
        itemOrdey: true,
        userId: this.userToken,
        DisableItemContent: true,
      };

      var centent = JSON.stringify(obj);
      $.ajax({
        type: "post",
        contentType: "application/json",
        url: this.apiUrl + "/Api/Source/Item",
        data: centent,
        success: function (res) {
          var datas = res.Data;
          that.achievementDetailsList = datas.Items;
          that.dataCount = res.Count;
          //console.log(datas.Items);
          //console.log(JSON.stringify(res.Data)+'qqqqqqqqqq')
        },
      });
    },
    handleSizeChange(val) {
      let that = this;
      that.pageSize = val;
      //console.log(`每页 ${val} 条`);
    },
    handleCurrentChange(val) {
      let that = this;
      //console.log(`当前页: ${val}`);
      that.send("", "", "", val);
    },
    // 奖励详情
    achievementDetailsFun(item) {
      let that = this;
      let detailsarr = "";
      var obj = {
        rootId: item.RootId,
        itemId: item.ItemId,
        typeCode: item.TypeCode,
        supItemIdNum: item.ParentId,
        userId: this.userToken,
      };

      var centent = JSON.stringify(obj);

      $.ajax({
        type: "post",
        contentType: "application/json",
        url: this.apiUrl + "/Api/ItemApi/EditDetail",
        data: centent,
        success: function (res) {
          // that.detailsCentent = res;
          // console.log(JSON.stringify(res.Data)+'qqqqqqqqqq')
          detailsarr = res.Data.Attr;
          that.$refs.sonV.childMethods(res);
          for (var i = 0; i < detailsarr.length; i++) {
            if (detailsarr[i].Source == "ItemName") {
              that.detailsTitle = detailsarr[i].Value;
            } else if (detailsarr[i].Source == "CrDate") {
              that.releaseTime = detailsarr[i].Value;
            }
          }
        },
      });
      document.documentElement.scrollTop = document.body.scrollTop = 0;
      this.dialogTableVisible = true;
    },

    //地址详情页跳转
    UrlDetailRedirect() {
      // debugger
      let that = this;
      let rootIdNum = 10000;
      let itemIdNum = this.$route.query.itemId;
      let typeCodeNum = this.$route.query.typeCode;
      let supItemIdNum = this.$route.query.supItemId;
      let names = this.$route.query.name;
      if (!(itemIdNum > 0)) return;
      var obj = {
        RootId: rootIdNum,
        ItemId: itemIdNum,
        TypeCode: typeCodeNum,
        ParentId: supItemIdNum,
      };
      that.achievementIndex = typeCodeNum;
      if (names != "" && names != undefined) {
        setTimeout(function () {
          that.achievementTitle = names;

          that.send(rootIdNum, itemIdNum, typeCodeNum);
        }, 0);
        return;
      }

      that.achievementDetailsFun(obj);
    },
  },
};
</script>

<style></style>
